import { CircularProgress } from '@mui/material';
import { styled, ThemeProvider } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { useSelector } from 'react-redux';
import { HistoricalChart } from '../../Config/api';
import { theme } from './theme';
import SelectButton from '../SelectButton';
import { chartDays } from '../../Config/data';

const Container = styled('div')(({ theme }) => ({
  width: '75%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 25,
  padding: 40,
  '@media (max-width: 1280px)': {
    marginTop: 0,
    padding: 20,
    paddingTop: 0,
  },
  '@media (max-width: 1024px)': {
    marginTop: 0,
    padding: 20,
    paddingTop: 0,
  },
  '@media (max-width: 768px)': {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
  },
  '@media (max-width: 480px)': {
    padding: 0,
  },
}));

const CoinInfo = ({ coin }) => {
  const [historicDate, setHistoricDate] = useState();
  const [days, setDays] = useState(1);
  const curr = useSelector((state) => state.cur.exchangeArr);

  const fetchHistoricData = async () => {
    const { data } = await axios.get(
      HistoricalChart(coin.id, days, curr.currency ? curr.currency : 'USD')
    );

    setHistoricDate(data.prices);
  };

  useEffect(() => {
    fetchHistoricData();
  }, [days]);

  return (
    <ThemeProvider theme={theme}>
      <Container>
        {!historicDate ? (
          <CircularProgress
            style={{ color: 'gold' }}
            size={250}
            thickness={1}
          />
        ) : (
          <>
            <Bar
              data={{
                labels: historicDate.map((coin) => {
                  let date = new Date(coin[0]);
                  let time =
                    date.getHours() > 12
                      ? `${date.getHours() - 12}:${date.getMinutes()} PM`
                      : `${date.getHours()}:${date.getMinutes()} AM`;

                  return days === 1 ? time : date.toLocaleDateString();
                }),

                datasets: [
                  {
                    data: historicDate.map((coin) => coin[1]),
                    label: `Price ( Past ${days} Days ) in ${
                      curr.currency ? curr.currency : 'USD'
                    }`,
                    backgroundColor: '#36e413',
                    borderColor: '#73f826',
                    borderWidth: 1,
                    hoverBackgroundColor: 'f50f0f', // Изменение цвета столбцов при наведении
                    hoverBorderColor: '#f50f0f',
                  },
                ],
              }}
              options={{
                interaction: {
                  mode: 'index',
                  intersect: false,
                },
                plugins: {
                  tooltip: {
                    enabled: true,
                  },
                },
                scales: {
                  x: {
                    ticks: {
                      color: 'gold',
                    },
                    title: {
                      display: true,
                      text: 'Months',
                      color: 'white',
                    },
                  },
                  y: {
                    ticks: {
                      color: 'gold',
                    },
                    title: {
                      display: true,
                      text: 'Price',
                      color: 'white',
                    },
                  },
                },
                elements: {
                  point: {
                    radius: 1,
                  },
                  line: {
                    borderColor: '#fa820a', // Цвет линии наводки
                    borderWidth: '6px',
                  },
                },
                hover: {
                  mode: 'index',
                  intersect: false,
                  onHover: function (e, elements) {
                    if (elements.length) {
                      const ctx = e.chart.ctx;
                      ctx.save();
                      ctx.strokeStyle = 'rgba(255, 99, 132, 1)'; // Цвет линии при наведении
                      ctx.lineWidth = 4; // Толщина линии при наведении
                      ctx.restore();
                    }
                  },
                },
              }}
            />
            <>
              <div
                style={{
                  display: 'flex',
                  marginTop: 20,
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {chartDays.map((day) => (
                  <SelectButton
                    key={day.value}
                    onClick={() => setDays(day.value)}
                    selected={day.value === days}
                  >
                    {day.label}
                  </SelectButton>
                ))}
              </div>
            </>
          </>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default CoinInfo;
